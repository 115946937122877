
















































import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css'
import { Component, Vue } from 'vue-property-decorator'

import '@syncfusion/ej2-vue-buttons/styles/fabric.css';
import '@syncfusion/ej2-vue-inputs/styles/fabric.css';
import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
Vue.use(ButtonPlugin);
import { MaskedTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import { enableRipple } from '@syncfusion/ej2-base';
import { WebApi } from '../../../Helper/WebApi';
Vue.use(MaskedTextBoxPlugin);
enableRipple(true);
@Component({
     components: {
    VueTelInput,
  }

})
export default class Login extends Vue {
   submitButton:string = "Send Otp";
   otp_send:boolean = false;
   otp:string="";
   otperror:any=null;
   Mobileopt!: {
     id: "Mobile";
     name: "Mobile";
     maxlength: 18;
     required: true;
     placeholder: "Mobile Number";
   };
  ismobileFocus:boolean= false;
  ismobilevalid: boolean = true;
  Mobile!: string;
  async submitButtonAction():Promise<void> {
    
    if(!this.ismobileFocus || !this.ismobilevalid || this.Mobile.length<8 )
      {
        this.ismobilevalid = false;
        this.ismobileFocus = true;
        return;
      }
      if(this.otp_send){ 
        if(this.otp.length!=6)
        
          this.otperror = "Please enter 6 digit OTP"
        else{
          this.otperror = null;
       await WebApi.Login(this.Mobile,this.otp,this.$browserDetect.meta.name+","+this.$browserDetect.meta.version,this.$browserDetect.meta.ua).then(d=>{
         if(d==true){
           this.$router.push({name : "Dashboard"});
         }
       });   
       
        }
      }
      else {
        await WebApi.sendOTP(this.Mobile).then((rs)=>{
              this.otp_send = rs;
              if(rs==true)
              this.submitButton = "Login";
              this.$store.commit("ChangeLoading", false);
        });
      }
     
  }
  MobileNumberChange(num:any,data:any) {
    
      if(this.ismobileFocus){
      this.ismobilevalid = data.valid;
      if(data.valid === true)
      this.Mobile = "+"+data.countryCallingCode+"-"+data.nationalNumber;
      }
     }
     MobileNumberfocus(){
       console.log("focus");
       this.ismobileFocus = true;
       if((this.Mobile==null || this.Mobile.length<5) && this.ismobilevalid)
       this.ismobilevalid = false;
     }
     resendOtp(){
       this.otp_send = false;
       this.submitButtonAction();
     }
}

